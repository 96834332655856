#Premium {
	width: 100%;
	height: 100%;
}

#Premium .background {
	height: 100%;
	width: 100%;
	position: relative;
	align-content: center;
	justify-content: center;
	grid-template-rows: auto;
	grid-template-columns: 1fr;
	background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
}


#Premium .premium-buttons {
	margin: 0;
	font-size: 16px;
	padding: 0 8px;
	text-align: center;
	color: #fff !important;
}

#Premium .logo {
	height: 100%;
	max-height: 180px;
	margin: 0 auto;
}

#Premium .content {
	width: 100%;
	height: 100%;
}

#Premium .socialLogin {
	gap: 1px;
	height: 5px;
	display: grid;
	font-size: 14px;
	color: #616161;
	font-weight: 800;
	border-radius: 1rem;
	align-items: center;
	justify-content: center;
	grid-template-rows: auto;
	text-transform: uppercase;
	grid-template-columns: repeat(2, auto);
	background-color: #fff;
}


#Premium .assinar {
	font-weight: 800;
	cursor: pointer;
	font-size: 18px;
	padding: 18px 0px;
	flex: 1;
	text-align: center;
	background: #d63d2f;
	border-radius: 30px;
	color: #fff;
	display: block;
	text-transform: uppercase;
  
	margin: 10px 20px 10px 20px;
}

#Premium .socialLogin img {
	width: 30px;
}

#Premium p {
	font-size: 22px;
	color: rgb(0, 0, 0);
}

#Premium .privacy {
	margin: 0;
	font-size: 14px;
	padding: 0 16px;
	line-height: 20px;
	text-align: center;
	color: #fff !important;
}

#Premium .signup {
	margin: 0;
	font-size: 16px;
	text-align: center;
	color: #fff !important;
}

#Premium ion-router-link,
#Premium a {
	font-weight: 600;
	color: #fff !important;
	text-decoration: underline;
}

/*https://dev.to/ionic/how-to-css-in-ionic-react-with-styled-components-2eo6*/
