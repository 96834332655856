#Initial {
	width: 100%;
	height: 100%;
}

#Initial .titulo {
	display: grid;
	position: relative;
	align-items: center;
	justify-content: center;
	grid-template-rows: auto;
	grid-template-columns: 1fr;
	height: 45%;
	padding: 0 16px;
	background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
}

#Initial .background {
	height: 100%;
	display: grid;
	position: relative;
	align-items: center;
	justify-content: center;
	grid-template-rows: auto;
	grid-template-columns: 1fr;
	background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
}

#Initial .logo {
	height: 100%;
	max-height: 180px;
	margin: 0 auto;
}

#Initial .content {
	width: 100%;
	height: 100%;
	margin: 3;
}

#Initial .imgColu1 {		
	gap: 10px;	
	display: grid;				
	justify-content: center;
	grid-template-rows: auto;
	text-transform: uppercase;
	grid-template-columns: repeat(4, auto);		
}

#Initial .imgColu {		
	gap: 1px;	
	display: grid;				
	justify-content: center;
	grid-template-rows: auto;
	text-transform: uppercase;
	grid-template-columns: repeat(4, auto);		
}

#Initial .imgColu2 {		
	gap: 12px;	
	display: grid;				
	justify-content: center;
	grid-template-rows: auto;
	text-transform: uppercase;
	grid-template-columns: repeat(4, auto);		
}


#Initial .textColu {
	gap: 10px;	
	display: grid;				
	justify-content: center;
	grid-template-rows: auto;
	text-transform: uppercase;
	grid-template-columns: repeat(4, auto);	
}

#Initial .socialLogin {
	gap: 8px;
	height: 50px;
	display: grid;
	font-size: 14px;
	color: #616161;
	font-weight: 800;
	border-radius: 2rem;
	align-items: center;
	justify-content: center;
	grid-template-rows: auto;
	text-transform: uppercase;
	grid-template-columns: repeat(2, auto);
	background-color: #fff;
	width: 100%;
	box-shadow: 0px 4px 5px rgb(0 0 0 / 11%);
}

#Initial .socialLogin img {
	width: 30px;
	height: 50px;
}


#Initial p {
	font-size: 22px;
	color: rgb(0, 0, 0);
}

#Initial .privacy {
	margin: 0;
	font-size: 14px;
	padding: 0 16px;
	line-height: 20px;
	text-align: center;
	color: #fff !important;
}

#Initial .signup {
	margin: 0;
	font-size: 16px;
	text-align: center;
	color: #fff !important;
}

#Initial ion-router-link,
#Initial a {
	font-weight: 600;
	color: #fff !important;
	text-decoration: underline;
}