/* ion-toolbar {
	height:50px !important;
}
ion-tab-bar {
	height:50px !important;
} */

.reactEasyCrop_CropArea {
	color: rgba(0, 0, 0, 0.9);
}

.opacity {
	opacity: 0.5;
}

.logoHeaderImg {
	padding-top: 0px;
	display: block;
	margin: auto;
	width: 30%;
	height: 30%;
}

@media (max-height: 543px) {
	.logoHeaderImg {
		width: 18%;
		height: 18%;
	}

	.height {
		height: 3.2em;
	}
}

.btn-home-likes {
	padding: 0px 0px 0px 0px;
	margin: 0px 0px 0px 0px;
}

.size {
	width: 3.7em;
	height: 3.7em;
}

.transform-up {
	margin-top: -22em;
	height: 44em;
}

.plan {
	cursor: pointer;
}

.plan:active {
	box-shadow: inset 0px 1px 1px 1px #0000004a;
}

.transform-l {
	margin-top: -2em;
	margin-left: -12em;
	margin-right: 16em;
}

.transform-r {
	margin-top: -2em;
	margin-left: 16em;
	margin-right: -12em;
}

.font-red {
	font-size: 10px;
	color: #d63d2f;
}

.ReactCrop {
	margin: 0 auto;
	width: 100%;
}

.ReactCrop div {
	margin: 0 auto;
	max-height: 70vh;
	display: flex;
}

.ReactCrop img {
	max-height: 100%;
	max-height: 70vh;
}

.btnSignIn {
	font-weight: 800;
	font-size: 16px;
	padding: 18px;
	flex: 1;
	text-align: center;
	background: #fff;
	border-radius: 72px;
	text-decoration: none;
	display: inline-block;
}

.modal-wrapper {
	/*height: 100vh !important;*/
}

.btns-like-deslike {
	margin-left: -2em;
	width: 138%;
	margin-top: -1em;
}

@media (max-width: 400px) {
	.btns-like-deslike {
		width: 130%;
	}
}

@media (max-width: 540px) {
	/* .h-31 {
		height: 46em !important;
	} */
}

@media (min-width: 1024px) {
	/* .h-31 {
		height: 35.23em !important;
	} */

	html {
		display: flex;
		justify-content: center;
		align-items: center;
		background: #e8e8e8;
	}

	body:before {
		content: '';
		display: block;

		width: 500px;
		height: 900px;
		position: relative;
		left: -32px;
		top: -50px;
		background-image: url('phone-frame.png');
		background-size: 400px auto;
		background-repeat: no-repeat;
	}

	ion-app {
		border-radius: 28px 28px 28px 28px;
	}

	body {
		width: 336px;
		margin: auto;
		height: 700px;
	}
}

@media (max-width: 320px) {
	p {
		font-size: 14px;
	}
}

.ReactCrop__crop-selection {
	border-radius: 50%;
}

.my-class {
	box-shadow: 0em 1em 44em 13em grey;
	height: 40% !important;
	width: 78% !important;
	margin: 0 auto;
	top: 12em;
	border-radius: 20px;
}

/* scroll */

/* width */
::-webkit-scrollbar {
	width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px #da5744;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #da5744;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #da5744;
}

.color-btn-tabs {
	--color: #da57447a;
}

/* modal */
.modal-tutorial .modal-wrapper.sc-ion-modal-md {
	/* height: 96vh !important; */
	height: 100%;
}

.alert-wrapper.sc-ion-alert-ios {
	width: 85%;
	max-width: 90%;
}

/* modal tutorial */
/* @media (min-height: 605px) and (max-height: 659) {
	.modal-tutorial .modal-wrapper {
		height: 91vh !important;
	}
}

@media (min-height: 660px) and (max-height: 715) {
	.modal-tutorial .modal-wrapper {
		height: 84vh !important;
	}
}
@media (min-height: 716px) and (max-height: 773) {
	.modal-tutorial .modal-wrapper {
		height: 77vh !important;
	}
}

@media (min-height: 773px) {
	.modal-tutorial .modal-wrapper {
		height: 51vh !important;
	}
} */

ion-footer {
	background-color: #fff;
}