.filtrar {
	gap: 1px;
	height: 55px;
	display: grid;
	font-size: 14px;
	color: #ffffff;
	font-weight: 800;
	border-radius: 30px;
	align-items: center;
	justify-content: center;
	grid-template-rows: auto;
	text-transform: uppercase;
	grid-template-columns: repeat(2, auto);
	background-color: #d63d2f;
	box-shadow: 0px 4px 5px rgb(0 0 0 / 11%);

	margin: 0 20px 10px 20px;
}

.votar {
	gap: 1px;
	height: 40px;
	display: grid;
	font-size: 14px;
	color: #ffffff;
	font-weight: 800;
	border-radius: 30px;
	align-items: center;
	justify-content: center;
	grid-template-rows: auto;
	text-transform: uppercase;
	grid-template-columns: repeat(2, auto);
	background-color: #d63d2f;
	box-shadow: 0px 4px 5px rgb(0 0 0 / 11%);

	margin: 6px 20px 10px 20px;
}

.progress-outer {
	width: 100%;
	margin: 10px 2%;
	padding: 3px;
	text-align: center;
	background: #fff;
	border: 1px solid #dcdcdc;
	color: #fff;
	border-radius: 20px;
}

.progress-inner {
	min-width: 22%;
	white-space: nowrap;
	overflow: hidden;
	padding: 5px;
	border-radius: 20px;
	background-color: var(--ion-color-primary);
}

.detcartitulo {
	font-weight: 800;
	font-size: 15px;
}

.detcarsubtitulo {
	font-size: 14px;
	color: #ad0000;
}

.desclassificado {
	transform: translateY(340%);
	background-color: #888;
	color: #fff;
	z-index: 1000;
	position: relative;
	text-align: center;
	margin-left: 5px;
	margin-right: 5px;
	margin-top: -35px;
}

.detailcar {
	font-size: 13px;
	color: #434242;
	
	background-color: rgb(241, 241, 241);
	box-shadow: 0px 4px 5px rgb(0 0 0 / 11%);
	border-radius: 1rem;

	align-items: center;	
	justify-content: center;
	
	/* text-transform: uppercase; */
	
	padding: 0 16px;
	margin-top: 16px;
	margin-bottom: 16px;
	margin-left: 8px;
	margin-right: 4px;
}

.regra {
	font-size: 0.8em;
	margin: 20px;
}