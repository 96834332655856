#landing {
	width: 100%;
	height: 100%;
}

#landing .background {
	gap: 16px;
	height: 100%;
	display: grid;
	padding: 28px;
	position: relative;
	align-items: center;
	justify-content: center;
	grid-template-rows: auto;
	grid-template-columns: 1fr;
	background: linear-gradient(180deg, #110c0c 0%, #6c6c6c 100%);
}

#landing .logo {
	height: 100%;
	max-height: 185px;
	margin: 0 auto;
	padding: 8px 0;
}

#landing .content {
	gap: 12px;
	display: grid;
	align-items: center;
	justify-content: center;
	grid-template-rows: auto;
	grid-template-columns: 1fr;
}

#landing .socialLogin {
	gap: 8px;
	height: 50px;
	display: grid;
	font-size: 14px;
	color: #616161;
	font-weight: 800;
	border-radius: 2rem;
	align-items: center;
	justify-content: center;
	grid-template-rows: auto;
	text-transform: uppercase;
	grid-template-columns: repeat(2, auto);
	background-color: #fff;
}

#landing .socialLogin img {
	width: 30px;
}

#landing p {
	font-weight: 200;
}

#landing .privacy {
	margin: 0;
	font-size: 14px;
	padding: 0 16px;
	line-height: 20px;
	text-align: center;
	color: #fff !important;
}

#landing .signup {
	margin: 0;
	font-size: 16px;
	text-align: center;
	color: #fff !important;
}

#landing ion-router-link,
#landing a {
	font-weight: 600;
	color: #fff !important;
	text-decoration: underline;
}

/*https://dev.to/ionic/how-to-css-in-ionic-react-with-styled-components-2eo6*/
